import React from "react"
import { graphql, Script } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Personvern = ({ data }) => (
  <Layout>
    <Seo title={data?.wpPage?.title} />
    <main>
      <section className="page-default">
        <div className="container-fluid">
          <div className="page-default--inner">
            <h1>{data?.wpPage?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data?.wpPage?.content }} />
            <div id="CookieDeclaration" />
            <Script
              src="https://consent.cookiebot.com/2c4d8409-ec0a-48c0-8807-05813e6e66bf/cd.js"
              type="text/javascript"
              async
            />
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxMTA=" }) {
      title
      content
    }
  }
`

export default Personvern
